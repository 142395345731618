import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
//import { useNavigate } from 'react-router-dom';
import uploadIcon from "../imagens/icons/uploadIcon.png";
import trashIcon from "../imagens/icons/trashIcon.png";
import folderIcon from "../imagens/icons/folder.png";
import backIcon from "../imagens/icons/back.png";
import earIcon from "../imagens/icons/earIcon.png";
import locateIcon from "../imagens/icons/locateIcon.png";
import storeIcon from "../imagens/icons/storeIcon.png";
import Footer from "./Footer.js"
//import handlePlayVideo from "./playVideo.js";
import "../styles/DashboardPage.css";
import "../styles/Fonts.css";
import "../styles/Header.css";
import moment from "moment";


const backendUrl = process.env.REACT_APP_BACKEND_URL;


const DashboardPage = ({
  loggedIn,
  setLoggedIn,
  setUsername,
  username,
  handleLogout,
}) => { 
  const [backgroundColor, setBackgroundColor] = useState("");
  const [videos, setVideos] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [storeName, setStoreName] = useState(''); 
  const [storeFolders, setStoreFolders] = useState([]);
  const [screenName, setScreenName] = useState(''); 
  const [screenFolders, setScreenFolders] = useState([]);
  const [renderContent, setRenderContent] = useState('stores');
  const [testeUserName, setTesteUserName] = useState(username);
  const [selectedFile, setSelectedFile] = useState(null);
  const [navigationHistory, setNavigationHistory] = useState([]);
  const [fileDate, setFileDate] = useState([]);
  const [loadScreen, setLoadScreen] = useState(false);
  const [confirDelete, setSetConfirDelete] = useState(false);
  const [programScreen, setProgramScreen] = useState(false);
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [hour, setHour] = useState('');
  const [minute, setMinute] = useState('');
  const [selectedDay, setSelectedDay] = useState(null);

  //const navigate = useNavigate(); // Inicialize o useNavigate

  let inactivityTimer; // Variável para armazenar o temporizador de inatividade

  // Função para redefinir o temporizador de inatividade
  function resetInactivityTimer() {
    clearTimeout(inactivityTimer); // Limpa o temporizador existente, se houver
    inactivityTimer = setTimeout(handleInactivity, 5 * 60 * 1000); // Define um novo temporizador de inatividade para 5 segundos
  }
  
  // Função para lidar com a inatividade do usuário
  function handleInactivity() {
    //handleLogout(); 
  }
  
  // Adiciona listeners de eventos para rastrear interações do usuário
  document.addEventListener('click', resetInactivityTimer);
  document.addEventListener('mousemove', resetInactivityTimer);
  document.addEventListener('keypress', resetInactivityTimer);
  
  // Inicializa o temporizador de inatividade
  resetInactivityTimer();

  //------------------------------

  useEffect(() => {
    setTesteUserName(localStorage.getItem('username'))
    let client = sessionStorage.getItem('client')

    const fetchStores = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/folder?client=${client}`
        );
        if (!response.ok) {
          throw new Error("Erro ao buscar lojas.");
        }
        const data = await response.json();
        setStores(data.folders); // Define as lojas no estado
        setLoading(false);
      } catch (error) {
        setError("Erro ao buscar lojas. Tente novamente mais tarde."); // Define o erro no estado
        setLoading(false);
      }
    }
  
    fetchStores();
  
  }, [username])



  //cor do cabeçalio das caixas de localizacao/ecra/historico
  useEffect(() => {

  let client = sessionStorage.getItem('client')

    const getBgcolorBar = async () => {
      try {
        const response = await fetch(
          `${backendUrl}/bgCollor/${client}`,
          {
            method: "GET",
          }
        );

        const data = await response.json();	
        setBackgroundColor(data[0].bgCollor);
      } catch (error) {
        console.error("Erro ao buscar a cor de fundo:", error.message);
      }
    };
    getBgcolorBar();
  })


//rota get para historico
useEffect(() => {
  const fetchFilesDate = async (screenName) => {
    let client = sessionStorage.getItem('client')

    if (client && storeName && screenName) {
      try {
        const response = await fetch(`${backendUrl}/fileDate?client=${client}/${storeName}/${screenName}`);
        if (response.ok) {
          const data = await response.json();

          setFileDate(data); // Atualiza o estado com os dados recebidos
        } else {
          console.error('Erro ao buscar arquivos:', response.statusText);
        }
      } catch (error) {
        console.error('Erro ao buscar arquivos:', error);
      }
    }
  };

  fetchFilesDate(screenName);
}, [storeName, screenName]);
  


  

  useEffect(() => {

    let teste2 = localStorage.getItem('username')
    if(teste2 === null){
      teste2 = username
    }
    const fetchData = async () => {
      
      try {
        if (loggedIn) {
          //loginSuccess(teste2)

        } else {
          setBackgroundColor("");
          //setHeaderBgColor("");
        }
      } catch (error) {
        console.error("Erro ao buscar informações:", error.message);
      }
    };


    fetchData();

  }, [loggedIn, setLoggedIn, setUsername, username, testeUserName]);


  window.addEventListener('load', () => {
    const username = localStorage.getItem('username');
    if (username) {
    }
  });


  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }


  // Função para buscar as pastas dentro da loja atual
  const fetchStoreFolders = async (storeName) => {
    let client = sessionStorage.getItem('client')
    try {
      const response = await fetch(
        `${backendUrl}/folder?client=${client}/${storeName}`
      );
      if (!response.ok) {
        throw new Error("Erro ao buscar pastas da loja.");
      }
      const data = await response.json();

      setStoreFolders(data.folders); // Atualiza as pastas da loja no estado
      setStoreName(storeName); // Atualiza o nome da loja selecionada no estado
      //setCurrentPath(`/${teste2}/${storeName}`); // Atualiza o caminho atual
      setRenderContent('storeFolders');
    } catch (error) {
      console.error("Erro ao buscar pastas da loja:", error.message);
    
    }
    setNavigationHistory([...navigationHistory, 'stores']);
  };
  

  // Manipulador de evento para clicar em uma loja

  const handleStoreClick = (storeName) => {

    fetchStoreFolders(storeName);
  };

  const fetchScreenFolders = async (screenName) => {
    let client = sessionStorage.getItem('client')
    
    try {
      const response = await fetch(
        `${backendUrl}/folder?client=${client}/${storeName}/${screenName}`
      );
      if (!response.ok) {
        throw new Error("Erro ao buscar pastas e vídeos da ecrã.");
      }
      const data = await response.json();
  
  
      // Verifica se existem vídeos na resposta
      if (data && data.videos) {
        // Atualiza o estado dos vídeos
        setVideos(data.videos);
      } else {
        console.warn("Resposta da API não contém vídeos.");
      }
  
      // Atualiza o estado das pastas
      setScreenFolders(data.folders);
  
      // Atualiza o nome da tela selecionada e o caminho atual
      setScreenName(screenName);
      //setCurrentPath(`/${teste2}/${storeName}/${screenName}`);
      setRenderContent('screenFolders');
    } catch (error) {
      console.error("Erro ao buscar pastas e vídeos da ecrã:", error.message);
     
    }
    setNavigationHistory([...navigationHistory, 'stores']);
  };
  

  
  
  
  const handleScreenClick = (screenName) => {
    fetchScreenFolders(screenName);
  };

  const handleBackButtonClick = () => {
    window.location.reload();
  };



    const toggleVideoSelection = (video) => {
      const isSelected = selectedVideos.includes(video);
      if (isSelected) {
        setSelectedVideos(selectedVideos.filter((v) => v !== video));
      } else {
        setSelectedVideos([...selectedVideos, video]);
      }
    };

    //funcao de deletar videos individualmente

    const handleDeleteVideos = async (req, res) => {
      let client = sessionStorage.getItem('client')

      try {
        const url = `${backendUrl}/delete-video`; // Rota correta para exclusão de vídeo
        
        // Verificando se há vídeos selecionados
        if (selectedVideos.length === 0) {
          console.warn('Nenhum vídeo selecionado para exclusão.');
          return;
        }
    
        // Enviar uma solicitação DELETE para excluir os vídeos selecionados no servidor
        await Promise.all(selectedVideos.map(async (video) => {
          const requestOptions = {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
              client:client, 
              directory: `${storeName}/${screenName}`,
              videoName: video // Renomear para videoName
            }),
          };
          
          const response = await fetch(`${url}?client=${client}/${storeName}/${screenName}&videoName=${video}`, requestOptions);
          console.log ('response', response)
          
          // Verificando se a exclusão foi bem-sucedida
          if (response.ok) {
           alert("Vídeo excluído com sucesso.");
          }else{
            throw new Error(`Falha ao excluir o vídeo ${video}.`);
          }
        }));
    
        // Atualizar a lista de vídeos após a exclusão
        const updatedVideos = videos.filter((video) => !selectedVideos.includes(video));
        setVideos(updatedVideos);
        
        // Limpar a seleção de vídeos após a exclusão
          setSelectedVideos([]);
        } catch (error) {
          console.error('Erro ao excluir vídeos:', error.message);
        }
    };
      
    //abre confirmacao de tela de delet 
    const confirmDeletScreen = () => {
      setSetConfirDelete(prevState => !prevState);
      
    };
    //funcao de deletar todos os videos do tipo de ecra escolhido

    const handleDeleteAllVideos = async (client, screenName) => {
      client = sessionStorage.getItem('client')
      confirmDeletScreen();
      try {
        // Obtém todos os inputs do tipo checkbox
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        let checkedBox = false
        // Itera sobre os checkboxes para verificar quais estão marcados
        checkboxes.forEach(checkbox => {
          if (checkbox.checked) {
            screenName = checkbox.className;
            checkedBox = true
          }
        });
        if(!checkedBox){
          alert("Por favor, selecione pelo menos um vídeo para excluir.")
          return
        }
        
        // Envia a solicitação para a rota /uploadToAllStores
        const response = await fetch(`${backendUrl}/delete-all-video`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            client:client, 
            screenName:screenName
          }),
        });
          
        // Verifica se a solicitação foi bem-sucedida
        if (response.ok) {
          alert('Vídeos excluídos com sucesso.')
          handleBackButtonClick()
        } else {
          console.error('Falha ao excluir o vídeo:', response.statusText);
        }
        } catch (error) {
          console.error('Erro ao excluir vídeos:', error.message);
        }
      };


      //armazena o video selcionado
    const adicionarVideo = (novoVideo) => {
      setVideos([...videos, novoVideo]);
    };

    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
    };

    
    //faz upload do video individualmente
    const handleUpload = async (e, ) => {

      let client = sessionStorage.getItem('client')

      try {
        setLoadScreen(true)
        
        // Verificar se selectedFile está definido e é um objeto de arquivo válido
        if (!selectedFile || !(selectedFile instanceof File)) {
          console.error('ficheiro inválido:', selectedFile);
          alert("Por favor, selecioneum ficheiro de vídeo.")
          setLoadScreen(false)
          return;
        }

        // Verificar se o arquivo é um vídeo pela extensão
        const allowedExtensions = ['mp4', 'avi', 'mov', 'wmv']; // Adicione outras extensões de vídeo, se necessário
        const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
          alert('Por favor, selecione um ficheiro de vídeo válido (MP4, AVI, MOV, WMV).');
          setLoadScreen(false)
          return;
        }

        const formData = new FormData();
        formData.append('video', selectedFile);
        formData.append('client', client); // Adicione username ao formData
        formData.append('storeName', storeName); // Adicione storeName ao formData
        formData.append('screenName', screenName); // Adicione screenName ao formData
        formData.entries().forEach(([key, value]) => {
        })
        if(renderContent !== 'screenFolders'){
          alert('Por favor, selecione uma pasta de ecrã.');
          setLoadScreen(false)
          return;
        }
        console.log('formData', formData)
      
        // Envia a solicitação para a rota /upload
        const response = await fetch(`${backendUrl}/upload`, {
        
          method: 'POST',
          body: formData
        });
        console.log('response', response)
    
        if (response.ok) {
          alert('Vídeo enviado com sucesso.');
        // Obter o nome do vídeo do FormData ou de outra fonte
        const nomeDoVideo = formData.get('video').name;
        // Adicionar o novo vídeo à lista de vídeos
        adicionarVideo(nomeDoVideo);
        } else {
          alert('Falha ao enviar o vídeo.');
        }
      } catch (error) {
        console.error('Erro ao enviar o vídeo:', error);
        alert('Erro ao enviar o vídeo. Por favor, tente novamente. Se o erro persistir, por favor, contacte o suporte.');
      } finally {
        setLoadScreen(false)
      }

    };


    //faz upload do video para todas as paras do tipo de ecra escolhido
    const uploadToAllStores = async (client, screenName) => {
      client = sessionStorage.getItem('client')
    
      try {
        setLoadScreen(true)
        // Obtém todos os inputs do tipo checkbox
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        let checkedBox = false
        // Itera sobre os checkboxes para verificar quais estão marcados
        checkboxes.forEach(checkbox => {
          if (checkbox.checked) {
            screenName = checkbox.className;
            checkedBox = true
          }
        });
        //manda uma mensagem de erro se nenhum checkbox foi marcado
        if(!checkedBox){
          alert('Por favor, selecione uma ecrã para enviar o vídeo.')
          setLoadScreen(false)
          return
        }
        // Verificar se selectedFile está definido e é um objeto de arquivo válido
        if (!selectedFile || !(selectedFile instanceof File)) {
          alert('Por favor, selecione um ficheiro de vídeo.');
          console.error('ficheiro inválido:', selectedFile);
          setLoadScreen(false)
          return;
        }
        // Verificar se o arquivo é um vídeo pela extensão
        const allowedExtensions = ['mp4', 'avi', 'mov', 'wmv']; // Adicione outras extensões de vídeo, se necessário
        const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
        if (!allowedExtensions.includes(fileExtension)) {
          alert('Por favor, selecione um ficheiro de vídeo válido (MP4, AVI, MOV, WMV).');
          setLoadScreen(false)
          return;
        }
        // Cria um FormData para enviar os dados do vídeo
        const formData = new FormData();
        formData.append('video', selectedFile);
        formData.append('client', client); // Adicione username ao formData
        formData.append('screenName', screenName); // Adicione screenName ao formData
    
        
    
        // Envia a solicitação para a rota /uploadToAllStores
        const response = await fetch(`${backendUrl}/uploadToAllStores`, {
          method: 'POST',
          body: formData
        });
        
        // Verifica se a solicitação foi bem-sucedida
        if (response.ok) {
          alert('Vídeo enviado para todas as lojas com sucesso.');
          handleBackButtonClick();
        } else {
          console.error('Falha ao enviar o vídeo:', response.statusText);
        }
      } catch (error) {
        console.error('Erro ao enviar o vídeo:', error);
      } finally {
        setLoadScreen(false)
      }
    };

    /**agendamento de horario */

  // Dados do mês
  const startOfMonth = currentDate.startOf('month');
  //const endOfMonth = currentDate.endOf('month');
  const startDay = startOfMonth.day();
  const daysInMonth = currentDate.daysInMonth();
  const today = dayjs(); // Obtém o dia atual

  const previousMonth = () => {
    setCurrentDate(currentDate.subtract(1, 'month'));
    setSelectedDay(null);
  };
  const nextMonth = () => {
    setCurrentDate(currentDate.add(1, 'month'));
    setSelectedDay(null);
  }


  /*seleciona o dia e armazena no estado */
  const handleDayClick = (day) => {
    const selectedDate = dayjs(currentDate).set('date', day); // Dia clicado
  
    // Verifica se o dia selecionado é hoje
    if (selectedDate.isSame(today, 'day') || selectedDate.isSame(moment(today).add(1, 'day'), 'day')) {
      alert("Não é possível realizar o agendamento dentro de 48 horas.");
      return;
    }
  
    // Define o dia selecionado
    setSelectedDay(day);
  };
  console.log(selectedDay)

  // Manipuladores de entrada de hora e minuto
  const handleHourChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 0 && Number(value) <= 24)) {
      setHour(value);
    }
  };

  const handleMinuteChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 0 && Number(value) <= 60)) {
      setMinute(value);
    }
  };
  /*validacao de dia-ora-min*/
  /*const prepareData = () => {
    if (!selectedDay || hour === '' || minute === '') {
      alert('Por favor, preencha todos os campos!');
      return;
    }

    const formattedDateDay = dayjs(currentDate)
      .set('date', selectedDay)
      .format('DD/MM/YYYY');
    console.log('dia selecionado:', formattedDateDay);

    const formattedDateHour = dayjs(currentDate)
      .set('hour', Number(hour))
      .set('minute', Number(minute))
      .set('second', 0) 
      .format('HH:mm:ss');
    console.log('hora selecionada:', formattedDateHour);
  
  };*/

  const changeContentDate = async () => {
    let client = sessionStorage.getItem('client')

    if (!selectedDay || hour === '' || minute === '') {
      alert('Por favor, preencha todos os campos!');
      return;
    }

    const formattedDateDay = dayjs(currentDate)
      .set('date', selectedDay)
      .format('YYYY/MM/DD');

    const formattedDateHour = dayjs(currentDate)
      .set('hour', Number(hour))
      .set('minute', Number(minute))
      .set('second', 0) 
      .format('HH:mm:ss');

      console.log( 'dia selecionado:', formattedDateDay, 'hora selecionada:', formattedDateHour);

      const data = {
        client: client,
        storeName: storeName,
        screenName: screenName,
        formattedDateDay: formattedDateDay,
        formattedDateHour: formattedDateHour
      };

      console.log(data)
    
      try {
        const response = await fetch(`${backendUrl}/changeContentDate`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json' // Define que estamos enviando JSON
          },
          body: JSON.stringify(data) // Converte o objeto JavaScript para JSON
        });
    
        if (response.ok) {
          alert('Data de conteúdo alterada com sucesso.');
          handleBackButtonClick();
        } else {
          console.error('Erro ao alterar a data do conteúdo', response.statusText);
          alert(`Falha ao alterar a data do conteúdo. Status: ${response.status}. Tente novamente.`);
        }
      } catch (error) {
        console.error('Erro ao enviar a requisição:', error);
        alert('Ocorreu um erro ao tentar alterar a data do conteúdo.');
      }

  };




    // ============================= Renderização =======================================
    // Renderiza tela de carregamento de video

      const toggleLoadScreen = (
        <div className={loadScreen  ? 'loadingRender loading-open' : 'loadingRender loading-closed'}>
          {loadScreen && (
            <div className="loadingRender loading-open">
              <div className="loader">
              </div>
            </div>
          )}
        </div>
      );
    
    let content = null; 
  // Renderização do conteúdo da loja atual, incluindo pastas dentro da loja
  if (renderContent === 'stores') {
    // Se o conteúdo a ser renderizado são as lojas
    content = (
      <ul>
        {stores.map((store) => (
          <li key={store} onClick={() => handleStoreClick(store)}>
            <img className="folderN" src={locateIcon} style={{ cursor: 'pointer' }} alt="Ícone de pasta" />
            {store}
          </li>
        ))}
      </ul>
    );
  } else if (renderContent === 'storeFolders') {
    // Se o conteúdo a ser renderizado são as pastas da loja
    content = (
      <div>
        <ul>
          {storeFolders.map((folder) => (
            <li key={folder} onClick={() => handleScreenClick(folder)}>
              {/* Adicionar imagem condicional aqui */}
              {folder === 'implantes' || folder === 'aparelhos' || folder === 'acessorios' ? (
                <img 
                  className="folderN" 
                  src={earIcon}  // Caminho da primeira imagem específica
                  style={{ cursor: 'pointer' }} 
                  alt="Ícone de pasta" />
              ) : folder === 'montra' || folder === 'corner' || folder === 'ledwall' || folder ==='sala' ? (
                <img 
                  className="folderN" 
                  src={storeIcon}  // Caminho da segunda imagem específica
                  style={{ cursor: 'pointer' }} 
                  alt="Ícone de pasta" />
              ) : (
                <img 
                  className="folderN" 
                  src={storeIcon}  // Caminho da imagem genérica
                  style={{ cursor: 'pointer' }} 
                  alt="Ícone de pasta" />
              )}
              {folder}
            </li>
          ))}
        </ul>
      </div>
    );
  } else if (renderContent === 'screenFolders') {
    // Se o conteúdo a ser renderizado são as pastas de tela
    content = (
      <div>
        <ul>
          {/* Renderizar pastas de tela */}
          {screenFolders.map((folder) => (
            <li key={folder}>
              {folder}
            </li>
          ))}
          {/* Renderizar vídeos */}
          {videos.map((video) => (
            <li key={video}>
              {video}
              <input
                type="checkbox"
                checked={selectedVideos.includes(video)}
                onChange={() => toggleVideoSelection(video)}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  } else {
    content = <div>Conteúdo não disponível</div>;
  }
    
// Renderização do conteúdo do histórico, incluindo pastas dentro do histórico
let histContent = (
  <div>
    <ul>  
      {Array.isArray(fileDate.videos) && fileDate.videos.map((video, index) => (
        <li key={index}>
          <p>
            Nome: {video.nome} <br />
            Data de Modificação: {video.dataModificacao}
            Usuario: {username}
          </p>  
        </li>
      ))}
    </ul>
  </div>
);

//tipologia de ecra widex
let generalScreenWidex = (
  <div className="generalScreenContainer">
    <div className="generalScreenBox">
    <ul className="generalScreen">
      <li>
        <img className="folderN" src={earIcon} alt="Ícone de acessorios " />
         Acessorios <span className="orientacaoTela">| Vertical</span>
         <input
         className="acessorios"
          type="checkbox"
          />
      </li>
      <li>
        <img className="folderN" src={earIcon} alt="Ícone de aparelhos" />
         Aparelhos <span className="orientacaoTela">| Horizontal</span>
         <input
         className="aparelhos"
          type="checkbox"
          />
      </li>
      <li>
        <img className="folderN" src={earIcon} alt="Ícone de implantes" />
         Implantes <span className="orientacaoTela">| Horizontal</span>
         <input
         className="implantes"
          type="checkbox"
          />
      </li>
    </ul>
    <ul className="generalScreen">
      <li>
        <img className="folderN" src={storeIcon} alt="Ícone de corner" />
         Corner <span className="orientacaoTela">| Horizontal</span>
         <input
         className="corner"
          type="checkbox"
          />
      </li>
      <li>
        <img className="folderN" src={storeIcon} alt="Ícone de montra" />
         Montra <span className="orientacaoTela">| Vertical</span>
         <input
         className="montra"
          type="checkbox"
          />
      </li>
      <li>
        <img className="folderN" src={storeIcon} alt="Ícone de ledwall" />
         Ledwall <span className="orientacaoTela">| Ledwall</span>
         <input
         className="ledwall"
          type="checkbox"
          />
      </li>
      <li>
        <img className="folderN" src={storeIcon} alt="Ícone de sala" />
         Sala <span className="orientacaoTela">| Horizontal</span>
         <input
         className="sala"
          type="checkbox"
          />
      </li>
    </ul>
    </div>
  </div>
)

//tipologia de ecra plm
let generalScreenPlm = (
  <div className="generalScreenContainer">
    <div className="generalScreenBox">
      <ul className="generalScreen">
        <li>
          <img className="folderN" src={storeIcon} alt="Ícone de acessorios " />
          Tablets <span className="orientacaoTela">| Vertical/Horizontal</span>
          <input
          className="tablet"
            type="checkbox"
            />
        </li>
        <li>
          <img className="folderN" src={storeIcon} alt="Ícone de acessorios " />
          Expositores <span className="orientacaoTela">| Horizontal</span>
          <input
          className="expositor"
            type="checkbox"
            />
        </li>
        <li>
          <img className="folderN" src={storeIcon} alt="Ícone de acessorios " />
          Mupis <span className="orientacaoTela">| Vertical</span>
          <input
          className="muppie"
            type="checkbox"
            />
        </li>
      </ul>
      <ul className="generalScreen">
        <li>
          <img className="folderN" src={storeIcon} alt="Ícone de acessorios " />
          Televisores <span className="orientacaoTela">| Horizontal</span>
          <input
          className="TV"
            type="checkbox"
            />
        </li>
        <li>
          <img className="folderN" src={storeIcon} alt="Ícone de acessorios " />
          Ledwall <span className="orientacaoTela">| Horizontal</span>
          <input
          className="acessorios"
            type="checkbox"
            />
        </li>
        <li>
          <img className="folderN" src={storeIcon} alt="Ícone de acessorios " />
          Ecrã Transparente <span className="orientacaoTela">| Horizontal</span>
          <input
          className="ecra transparente"
            type="checkbox"
            />
        </li>
      </ul>
    </div>
  </div>
)

//tipologia de ecra hp
let generalScreenHp = (
  <p>HP</p>
)

//condicional de renderizacao baseado no "client"

let clientScreen = sessionStorage.getItem('client')
const generalscreen = {
  widex : generalScreenWidex,
  plmretail : generalScreenPlm,
  hp : generalScreenHp

}

//renderizacao de tela de confirmacao de exclusao de conteudo

let confirmDelet = (
  <div className={confirDelete  ? 'loadingRender loading-open' : 'loadingRender loading-closed'}>
    {confirDelete &&(
      <div className="confirmDeletContent">
        <p>Ao continuar, concorda em eliminar todo o conteúdo associado ao tipo de ecrã selecionado em todas as lojas.</p>
        <p>Tem a certeza de que deseja proceder?</p>

        <div className="confirmDeletBtns" >
          <button className="cancelDeletBtn" onClick={confirmDeletScreen}>Cancelar</button>
          <button className="confirmDeletBtn" onClick={handleDeleteAllVideos} >Proceder</button>
        </div>
      </div>
      )}
  </div>
);



  // Renderizar dias do calendário
  const renderDays = () => {
    const days = [];
    for (let i = 0; i < startDay; i++) {
      days.push(<div key={`empty-${i}`} className="day empty"></div>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDay = currentDate.date(day); 
      const isPastDay = currentDay.isBefore(today, 'day'); 


      days.push(
        <div
        key={day}
          className={`day ${isPastDay ? 'past-day' : ''} ${
          selectedDay === day && !isPastDay ? 'selected-day' : ''
        }`}
          onClick={() => handleDayClick(day)}
        >
          {day}
        </div>
      );
    }
    return days;
  };




let calendar = (
  <div className="calendar">
    <div className="calendar-header">
    <img className="btnCalendar prev" src={backIcon} onClick={previousMonth} alt="previousMonth" />
      <h2>{currentDate.format('MMMM YYYY')}</h2>
      <img className="btnCalendar" src={backIcon} onClick={nextMonth} alt="nextMonth" />
    </div>
    <div className="calendar-grid">
      <div className="day-name">Sun</div>
      <div className="day-name">Mon</div>
      <div className="day-name">Tue</div>
      <div className="day-name">Wed</div>
      <div className="day-name">Thu</div>
      <div className="day-name">Fri</div>
      <div className="day-name">Sat</div>
      {renderDays()}
    </div>
  </div>
);

  /*renderização de label de agendamento de horario*/

  let clock = (
     <div className="time-input">
     <label>
       Hora:
       <input
         type="number"
         value={hour}
         onChange={handleHourChange}
         placeholder="HH"
         max="24"
         min="0"
       />
     </label>
     <label>
       Minuto:
       <input
         type="number"
         value={minute}
         onChange={handleMinuteChange}
         placeholder="MM"
         max="60"
         min="0"
       />
     </label>
   </div>
  )



const toggleProgramScreen = () => {
  setProgramScreen(prevState => !prevState);
};


let programContent =(
  <div className ={programScreen ? 'renderCalendar calendar-open' : 'renderCalendar calendar-closed'}>
    {programScreen && (
      <div className="programContent calendar-open">
        <div className="calendarContent">
          {calendar}
        </div>
        <div className = "clockContent">
          {clock}
        </div>      
        <div className="programContent" >
          <img className="btnCloseCalendar" style={{ cursor: 'pointer' }} onClick={toggleProgramScreen} src={backIcon} alt="backIcon" />
          <img className="btnConfirmDate" style={{ cursor: 'pointer' }} onClick={changeContentDate} src={backIcon} alt="backIcon" />
        </div>
      </div>
      
    )}
  </div>
)



//------------------------------------------------ DashboardPage------------------------------------------------------------//

  return (
    <div className="dashboardWrapper">

      <div className="containerDashboard">
        <div className="generalContainerDashboard">
          <div className="generalBar" style={{ backgroundColor }}>
            <p className="txtGeneralBar">Localização {storeName ? ` - ${storeName}` : ''} {screenName ? ` - ${screenName}` : ''}</p>
            <div className="iconsGeneral">

                  {storeName && screenName && (
                    <>
                      <img className="backIcon" style={{ display: 'none' }} onClick={toggleProgramScreen} src={backIcon} alt="backIcon" />
                      {programContent}
                      <img className="backIcon" style={{ cursor: 'pointer' }} onClick={handleBackButtonClick} src={backIcon} alt="backIcon" />
                      
                      <label htmlFor="videoFile">
                        <img className="searchFle" style={{ cursor: 'pointer' }} src={folderIcon} alt="uploadIcon" title="Escolher ficheiro" />
                      </label>
                      <input type="file" id="videoFile" style={{ display: 'none' }} onChange={handleFileChange} />

                      <img className="uploadIcon" onClick={handleUpload} style={{ cursor: 'pointer' }} src={uploadIcon} alt="uploadIcon" title="Enviar vídeo" />
                      <img className="trashIcon" onClick={handleDeleteVideos} style={{ cursor: 'pointer' }} src={trashIcon} alt="trashIcon" title="Excluir vídeo selecionado" />
                      {toggleLoadScreen}
                    </>
                  )}
            </div>
          </div>
          <div className="infoGeneralContainer">
            <div>{content}</div>
          </div>
        </div>
          <div className="lojaHistoryContainer">
            <div className="lojaContainer">
              <div className="generalBar" style={{ backgroundColor }}>
                <p className="txtGeneralBar">Tipologia de Ecrã</p>
                <div className="iconsGeneral">

                  <label htmlFor="videoFile">
                    <img className="searchFle" style={{ cursor: 'pointer' }}  src={folderIcon} alt="uploadIcon" title="Escolher ficheiro" />
                  </label>
                  <input type="file" id="videoFile" style={{ display: 'none' }} onChange={handleFileChange} />

                  <img className="uploadIcon" onClick={uploadToAllStores} style={{ cursor: 'pointer' }}  src={uploadIcon} alt="uploadIcon" title="Enviar vídeo para todas as pastas" />
                  <img className="trashIcon" onClick={confirmDeletScreen} style={{ cursor: 'pointer' }}  src={trashIcon} alt="trashIcon"title="Excluir vídeo de todas as pastas" />
                  {confirmDelet}
                  {/*{toggleLoadScreen}*/}
                </div>
              </div>
              <div className="infoGeneralContainerL">
                <div>{generalscreen[clientScreen]}</div> 
              </div>
          </div>
          <div className="historyContainer">
            <div className="generalBar" style={{ backgroundColor, height: '40px' } }>
              <p className="txtGeneralBar">Histórico</p>
              <div className="iconsGeneral">
              </div>
            </div>
            <div className="infoGeneralContainerL">
              <div>{histContent}</div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default DashboardPage;
