import React, { useState, useEffect } from "react";
//import dcmLogoAdmin from "../imagens/logos/dcmLogo.png";
import passwordIcon from "../imagens/icons/passwordIcon.png";
import reladIcon from "../imagens/icons/reloadIcon.png";
import configIcon from "../imagens/icons/configIcon.png";
import userIcon from "../imagens/icons/userIcon.png";
//import dcmLogo from "../imagens/logos/dcmLogo.png";
import logoutIcon from "../imagens/icons/exitIcon.png";
import hpLogo from "../imagens/logos/hpLogo.png";
import plmLogo from "../imagens/logos/plmLogo.png";
import widexLogo from "../imagens/logos/widex.png";
import settingsWIcon from "../imagens/icons/settingsWIcon.png";

import "../styles/Header.css";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Header = ({
    loggedIn,
    setLoggedIn,
    setUsername,
    username,
    handleLogout,
  }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [headerBgColor, setHeaderBgColor] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [greeting, setGreeting] = useState("");
    const [userOffice, setUserOffice] = useState("");
    const [logo, setLogo] = useState("");
    //const [loading, setLoading] = useState(true);
    const [bgSetting, setBgSetting] = useState('');
    const [error, setError] = useState(null);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth); 


    const handleBackButtonClick = () => {
        window.location.reload();
      };
      
    const toggleMenu = () => {
        setMenuOpen(prevState => !prevState);
      };
      


          // No logout, remova o nome de usuário do localStorage
     const logout = () => {
        handleLogout(); // Redireciona para a tela de login
        localStorage.removeItem('username');
        sessionStorage.clear();
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
          if (e.key === 'Escape') {
            setChangePassword(false);
          }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener when component unmounts
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []); 

    useEffect(() => {
        let client = sessionStorage.getItem('client');
        console.log(client)
    
        const getbgSetting = async () => {
          try {
            const response = await fetch(`${backendUrl}/settingIcon/${client}`, {
              method: 'GET',
            });
    
            if (response.status === 503) {
              // Exibir mensagem de manutenção
              document.getElementById('message').innerText = 'Site em manutenção. Por favor, tente novamente mais tarde.';
              setError('Site em manutenção. Por favor, tente novamente mais tarde.');
              return; // Retorna para não tentar processar a resposta
            }
    
            if (!response.ok) {
              throw new Error(`Erro ao buscar as configurações: ${response.statusText}`);
            }
    
            const data = await response.json();
            setBgSetting(data[0].bgSettings);
          } catch (error) {
            console.error('Erro ao buscar as configurações:', error);
            setError('Erro ao buscar as configurações. Tente novamente mais tarde.');
          }
        };
    
        getbgSetting();
      }, []); 
    
      useEffect(() => {

        let client = sessionStorage.getItem('client')
        
        const getImageLogo = async () => {
          try {
            const response = await fetch(
              `${backendUrl}/userImageLogo/${client}`,
              {
                method: "GET",
              }
            );
            const data = await response.json();
            setLogo(data[0].imageLogo);
          } catch (error) {
            console.error("Erro ao buscar a imagem:", error.message);
          }
        };
        getImageLogo()
    
         // Função para atualizar a largura da tela e verificar a mudança no tamanho da janela
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
            console.log("Largura da tela:", screenWidth);
            console.log ("o logo é", logo)
         };
    
        // Adiciona um event listener para monitorar o redimensionamento da tela
        window.addEventListener("resize", handleResize);
    
        // Remove o event listener quando o componente for desmontado
        return () => {
          window.removeEventListener("resize", handleResize);
        };
    
      },)

    useEffect(() => {

        let client = sessionStorage.getItem('client')
    
      const getHeaderBgColor = async () => {
        try {
          const response = await fetch(
            `${backendUrl}/bgCollorHeader/${client}`,
            {
              method: "GET",
            }
          );
          const data = await response.json();
          if (data.length === 0 || !data[0].bgCollorHeader) {
            console.error("Cor de fundo não encontrada ou indefinida.");
            return;
          }
          setHeaderBgColor(data[0].bgCollorHeader);
        } catch (error) {
          console.error("Erro ao buscar a cor de fundo:", error.message);
        }
      };  
        getHeaderBgColor();
    })

    useEffect(() => {
    let client = sessionStorage.getItem('client')
    let username = localStorage.getItem('username')
    const getOffice = async () => {
        try {
        const response = await fetch(
            `${backendUrl}/userOffice/${client}/${username}`,
            {
            method: "GET",
            }
        );
        const data = await response.json();
        setUserOffice(data[0].office);
        
        } catch (error) {
        console.error("Erro ao buscar o cargo:", error.message);
        }
    };
    getOffice();
    },[username])
    
    
    useEffect(() => {
    let client = sessionStorage.getItem('client')
    let username = localStorage.getItem('username')
    const getClientName = async () => {
        try {
        const response = await fetch(
            `${backendUrl}/clientName/${client}/${username}`,
            {
            method: "GET",
            }
        );
        const data = await response.json();
        setGreeting(`Olá, ${data[0].clientName}`);
        
        } catch (error) {
        console.error("Erro ao buscar o cargo:", error.message);
        }
    };
    getClientName();
    },[username])


  

      const handleChangePassword = async (e) => {
        e.preventDefault();
    
        // Verificar se as senhas coincidem
        if (newPassword !== confirmPassword) {
          //setErrorMessage('As senhas não coincidem');
          return;
        }
    
        try {
          // Enviar solicitação para alterar a senha
          const response = await fetch(`${backendUrl}/change-password`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username,
              oldPassword,
              newPassword,
              confirmPassword,
            }),
          });
    
          const data = await response.json();
    
          // Verificar se a solicitação foi bem-sucedida
          if (data.success) {
            // Redirecionar para a página de login
            alert('Senha alterada com sucesso.');
            window.location.href = '';
          } else {
            //setErrorMessage(data.message);
          }
        } catch (error) {
          console.error('Erro ao alterar a senha:', error);
          //setErrorMessage('Ocorreu um erro ao tentar alterar a senha');
        }
      };
  
      const toggleChangePassword = (e) => {
        toggleMenu();
        setChangePassword(prevState => !prevState);
      }


      useEffect(() => {
        const handleKeyDown = (e) => {
          if (e.key === 'Escape') {
            setChangePassword(false);
          }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener when component unmounts
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
        }, []); 

        

        if (error) {
            return <div>{error}</div>;
        }

      

        const settingMAp = {
            settingsWIcon : settingsWIcon,
            configIcon: configIcon
        }
        
        
        const logoMap = {
            widexLogo: widexLogo,
            plmLogoblack: plmLogo,
            hp:hpLogo
        };

      let changePasswordRender = (
        <div className='changePassword' >
          {changePassword && (
          
            <div className="changePasswordContainer" style={{backgroundColor: "#818285"}}>
               <form onSubmit={handleChangePassword}>
                <input
                className="userInput"
                  type="password"
                  placeholder="Senha Atual"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  required
                /><br />
                <input
                className="userInput"
                  type="password"
                  placeholder="Nova Senha"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                /><br />
                <input
                className="userInput"
                  type="password"
                  placeholder="Confirmar Nova Senha"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                /><br />
                <button className="passwordButton"  type="submit">Alterar Senha</button>
              </form>
              
            </div>   
          )}
        </div>
      );
    
      let togglemenuRender = (
        <div className={menuOpen ? 'menuRender menu-open' : 'menuRender menu-closed'}>
          {menuOpen && ( // Renderiza o menu apenas se menuOpen for true
            <ul className="menu">
              <li>
                <img className="menuImg" onClick={handleBackButtonClick} src={reladIcon} alt="Logo" />
                <p onClick={handleBackButtonClick} >Atualizar</p>
              </li>
              <li>
                <img className="menuImg" src={passwordIcon} alt="Logo" />
                <p onClick={toggleChangePassword}>Alterar Password</p>
              </li>
              <li>
                <img className="menuImg" onClick={logout} src={logoutIcon} alt="Logo" />
                <p onClick={logout} >Terminar Sessão</p>
              </li>
            </ul>
          )}
        </div>
      );


    return ( 
        <header className="header" style={{ backgroundColor: headerBgColor }}>
            <div className="logoUsercontainer">
                <img className="dcmLogoAdmin" src={logoMap[logo]} alt="Logo" />
                <div className="userOffice">
                  <img className="userIcon" style={{backgroundColor: "#818285", borderRadius: '50%'}} src={userIcon} alt="userIcon" />
                  <div className="textUserOffice">
                    <h1 className="helloUser">{greeting}</h1>
                    <p className="userOffice">{userOffice}</p>
                  </div>
                </div>
            </div>
            <div className="alertContainer">

            </div>
            <div className="programableContent">
                
            </div>
            <div className="imgLogoutPassword">
                <img className="logoutIcon" onClick={() => { toggleMenu() }} src={settingMAp[bgSetting]} alt="Options" />
                {togglemenuRender}
                {changePassword && changePasswordRender}
            </div>
        </header>
     );
}

export default Header;