import React, { useEffect, useState } from "react";
import "../styles/MonitoringPage.css";
import firLogoW from '../imagens/logos/firLogoW.png'
import firLogoB from '../imagens/logos/firLogoB.png'
import sloganFir from '../imagens/logos/slogan.png'
import widexLogo from '../imagens/logos/widex.png'
const backendUrl = process.env.REACT_APP_BACKEND_URL;
const MonitoringPage = () => {
    const [time, setTime] = useState("--:--");
    const [locations, setLocations] = useState([]);

    // Função para obter a data/hora atual
    function dateNow() {
        const date = new Date();
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = String(date.getFullYear()).padStart(2, "0");
        const hour = String(date.getHours()).padStart(2, "0");
        const minute = String(date.getMinutes()).padStart(2, "0");
        return `${day}-${month}-${year} ${hour}:${minute}`;
    }

    const fetchLocations = async () => {
        fetch(`${backendUrl}/getDiviceStatus`) // Ajuste a URL para sua API
        .then((response) => response.json())
        .then((data) => setLocations(data))
        .catch((error) => console.error("Erro ao buscar dados:", error));
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(dateNow());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    // Buscar dados do backend
    useEffect(() => {
        fetchLocations();
        setInterval(() => {
            fetchLocations();
        }, 20000);
    }, []);


    return (
        <div className="mpContainer">
            <div className="mpHeader">
                <img className= 'firLogoW' src={widexLogo} alt="logoFir" />
                <h2>Device Mapping System</h2>
                <h2>{time}</h2>
            </div>
            <div className="mpTableContent">
                <div className="mpTable">

                    <div className="mpTableleft">
                        <div >
                            <div className="rowHeaderContent">
                                <h3 className="title mploja rBorderLeft">Localização</h3>
                                <h3 className="title mpEcra ">Módulo</h3>
                                <h3 className="tStatus mpStatus rBorderRight">Status</h3>
                            </div>
                            {locations
                                .filter(item => item.working === 0) // Filtra apenas os itens onde working === 1
                                .map((item, index) => (
                                    <div className="rowContent" key={index}>
                                        <p className="content mploja">{item.storeName}</p>
                                        <p className="content mpEcra">{item.screenName}</p>
                                        <span className="status mpStatus on" style={{ color: "red", backgroundColor: "rgba(255, 8, 0, 0.2)" }}>
                                            OFF
                                        </span>
                                    </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="mpTable">
                    <div className="mpTableleft">
                        <div>
                            <div className="rowHeaderContent">
                                <h3 className="title mploja rBorderLeft">Localização</h3>
                                <h3 className="title mpEcra ">Módulo</h3>
                                <h3 className="tStatus mpStatus rBorderRight">Status</h3>
                            </div>
                            {locations
                                .filter(item => item.working === 1) // Filtra apenas os itens onde working === 1
                                .map((item, index) => (
                                    <div className="rowContent" key={index}>
                                        <p className="content mploja">{item.storeName}</p>
                                        <p className="content mpEcra">{item.screenName}</p>
                                        <span className="status mpStatus on" style={{ color: "green", backgroundColor: "rgba(0, 255, 0, 0.2)" }}>
                                            ON
                                        </span>
                                    </div>
                            ))}
                        </div>
                    </div>     
                </div>         
            </div>
            <div className="mpFooter">
                <img className= 'firLogoW' src={firLogoB} alt="logoFir" />

                <img className= 'firLogoW' src={sloganFir} alt="logoFir" />
            </div>
        </div>
    );
};

export default MonitoringPage;
